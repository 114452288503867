import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiClientManager } from "../../Business/api/ApiClientManager";
import { AuthApiClient } from "../../Business/api/AuthApiClient";
import { socket } from "../../socket";

export interface AuthState {
    authRegistrationError: string;
    registrationSuccess: boolean;
    loginError: string;
    accessToken: string;
    loggedInAt: number;
}

export const initialState: AuthState = {
    authRegistrationError: "",
    registrationSuccess: false,
    loginError: "",
    accessToken: localStorage.getItem('token') || "",
    loggedInAt: parseInt(localStorage.getItem('lastLogin') || "0")
}

interface RegisterPayload {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    clientId: string;
    locale: string;
    company: string;
    position?: string;
    phone?: string;
    country: string;
}

interface RegisterActionResponse {
    success: boolean;
    message: string;
}

interface LoginPayload {
    email: string;
    password: string;
    clientId: string;
}

interface LoginActionResponse {
    res: string;
    success: boolean;
}

export const RegisterAction = createAsyncThunk<RegisterActionResponse, RegisterPayload>(
    'auth/registerAction',
    async (registerPayload) => {
        try {
            await ApiClientManager.getInstance().getApiClient<AuthApiClient>(AuthApiClient.key).Register(registerPayload.firstName, registerPayload.lastName, registerPayload.email, registerPayload.password, registerPayload.clientId, registerPayload.locale, registerPayload.company, registerPayload.country, registerPayload.phone, registerPayload.position);
            return { success: true, message: "" };
        }
        catch (error: any) {
            let msg = "";

            console.log(error);
            if (error.response) 
                msg = error.response.data.message;
            else
                msg = error.message;

            return { success: false, message: msg };
        }

    }
)

export const LoginAction = createAsyncThunk<LoginActionResponse, LoginPayload>(
    'auth/loginAction',
    async (loginPayload) => {
        try {
            const token = await ApiClientManager.getInstance().getApiClient<AuthApiClient>(AuthApiClient.key).Login(loginPayload.email, loginPayload.password, loginPayload.clientId);
            return { res: token, success: true };
        }
        catch (error: any) {
            console.log(error);
            if (error && error.response && error.response.data)
                return { res: error.response.data.message, success: false };
            else
                return { res: error.message, success: false };
        }
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.accessToken = "";
            state.loggedInAt = 0;
            localStorage.removeItem('token');
            localStorage.removeItem('lastLogin');
            socket.disconnect();
        },
        loadToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
            localStorage.setItem('token', action.payload);
        },
        resetRegistrationData: (state) => {
            state.authRegistrationError = "";
            state.registrationSuccess = false;
        },
        setLoggedInAt: (state, action: PayloadAction<number>) => {
            state.loggedInAt = action.payload;
            localStorage.setItem('lastLogin', action.payload.toString());
        }
    },
    extraReducers: (builder) => {
        builder.addCase(RegisterAction.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.authRegistrationError = "";
                state.registrationSuccess = true;
            }
            else {
                state.authRegistrationError = action.payload.message;
            }
        });
        builder.addCase(LoginAction.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.loginError = "";
                state.accessToken = action.payload.res;
                state.loggedInAt = (new Date()).getTime();
                localStorage.setItem('lastLogin', state.loggedInAt.toString());
                localStorage.setItem('token', action.payload.res);
            }
            else
                state.loginError = action.payload.res;
        });
    }
});

export const { logout, loadToken, resetRegistrationData, setLoggedInAt } = authSlice.actions;

export default authSlice.reducer;