/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { PeriodPicker } from '../Inputs/PeriodPicker';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Section } from './Section';
import { DaysPicker } from '../Inputs/DaysPicker';
import { Input } from '../Inputs/Input';
import { SectorPicker } from '../Inputs/SectorPicker';
import { Advertiser, Agency, BriefDTO, Country, Currency, DiscountRate, MediaChannel, Product, Sector, Target } from '../../Business/dto/Brief';
import { TargetPicker } from '../Inputs/TargetPicker';
import './GeneralSection.css';
import { DefaultInput } from '../Inputs/DefaultInput';
import { MediaChannelPicker } from '../Inputs/MediaChannelPicker';
import { useAppDispatch, useAppSelector } from '../../Redux/hooks';
import { RetrieveDataFromExternalSource, setInputValue } from '../../Redux/Reducers/briefSlice';
import { FormatPicker } from '../Inputs/FormatPicker';
import { CountryPicker } from '../Inputs/CountryPicker';
import { JsonReplacer, StringReplacer } from '../../Business/utils/StringReplacer';
import { CurrencyPicker } from '../Inputs/CurrencyPicker';
import { AgencyPicker } from '../Inputs/AgencyPicker';
import { AdvertiserPicker } from '../Inputs/AdvertiserPicker';
import { ProductPicker } from '../Inputs/ProductPicker';
import { DataKey } from '../../Business/dto/ExternalSystemElement';
import { DataRetrieval } from '../../Business/dto/ExternalSystemRetrieveBody';
import { useTranslation } from 'react-i18next';
import { SellingStrategyOption } from '../Inputs/SellingStrategyPicker';
import { DiscountRateInput } from '../Inputs/DiscountRateInput';
import { ExternalSystemElementApiClient } from '../../Business/api/ExternalSystemElementApiClient';
import { ApiClientManager } from '../../Business/api/ApiClientManager';
import { dateObjectToNumber } from '../../Business/utils/DateUtils';

interface GeneralSectionProps {
    setSectionValidated: (sectionIdx: number, validated: boolean) => void;
    nextButtonDisabled: boolean;
    idx: number;
    maxMediaSelectionCount: number;
    retrieveSectorsUrl: string;
    retrieveSectorsBody: any;
    retrieveSectorsMethod: "get" | "post";
    retrieveSectorsParser: any;
    retrieveTargetsUrl: string;
    retrieveTargetsBody: any;
    retrieveTargetsMethod: "get" | "post";
    retrieveTargetsParser: any;
    retrieveMediaChannelsUrl: string;
    retrieveMediaChannelsBody: any;
    retrieveMediaChannelsMethod: "get" | "post";
    retrieveMediaChannelsParser: any;
    expanded: boolean;
    onChangeSection: (sectionIdx: number) => void;
    retrieveCountriesUrl: string;
    retrieveCountriesBody: any;
    retrieveCountriesMethod: "get" | "post";
    retrieveCountriesParser: any;
    useCountryIdToRetrieveMediaChannels: boolean;
    retrieveCurrenciesUrl: string;
    retrieveCurrenciesBody: any;
    retrieveCurrenciesMethod: "get" | "post";
    retrieveCurrenciesParser: any;
    retrieveAgenciesUrl: string;
    retrieveAgenciesBody: any;
    retrieveAgenciesMethod: "get" | "post";
    retrieveAgenciesParser: any;
    retrieveAdvertisersUrl: string;
    retrieveAdvertisersBody: any;
    retrieveAdvertisersMethod: "get" | "post";
    retrieveAdvertisersParser: any;
    retrieveProductsUrl: string;
    retrieveProductsBody: any;
    retrieveProductsMethod: "get" | "post";
    retrieveProductsParser: any;
    retrieveCompetitionCodesUrl: string;
    retrieveCompetitionCodesBody: any;
    retrieveCompetitionCodesKeysToReplaceInBody: string[];
    retrieveCompetitionCodesMethod: "get" | "post";
    retrieveCompetitionCodesParser: any;
    retrieveDiscountRateUrl: string;
    retrieveDiscountRateBody: any;
    retrieveDiscountRateKeysToReplaceInBody: string[];
    retrieveDiscountRateMethod: "get" | "post";
    retrieveDiscountRateParser: any;
    requireSellingStrategy: boolean;
    availableFormats: number[];
    briefToLoad?: BriefDTO;
}

interface GeneralSectionInputedValues {
    startDate: number;
    endDate: number;
    selectedDays: number[];
    selectedAgency?: Agency;
    selectedAdvertiser?: Advertiser;
    selectedProduct?: Product;
    selectedSector?: Sector;
    selectedTarget?: Target;
    selectedMediaChannels: MediaChannel[];
    selectedCountry?: Country;
    selectedCurrency?: Currency;
    netBudget: number;
    sellingStrategyType?: SellingStrategyOption;
    sellingStrategyValue?: number;
    selectedFormats: number[];
}

let tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

export class GeneralSection extends Section<GeneralSectionProps> {
    Name = "txt_general_criteria";

    SectionBody = (props: GeneralSectionProps) => {
        const { t } = useTranslation();
        const dispatch = useAppDispatch();
        const userDetails = useAppSelector(state => state.user.details);
        const retrievedSectors = useAppSelector(state => state.brief.retrievedData[DataKey.Sector]);
        const retrievedMediaChannels = useAppSelector(state => state.brief.retrievedData[DataKey.MediaChannel]);
        const competitionCodes = useAppSelector(state => state.brief.retrievedData[DataKey.Competition]);
        const [inputValues, setInputValues] = React.useState<GeneralSectionInputedValues>({
            startDate: tomorrow.getFullYear() * 10000 + (tomorrow.getMonth() + 1) * 100 + tomorrow.getDate(),
            endDate: tomorrow.getFullYear() * 10000 + (tomorrow.getMonth() + 1) * 100 + tomorrow.getDate(),
            selectedDays: [ 0, 1, 2, 3, 4, 5, 6 ],
            selectedMediaChannels: [],
            selectedFormats: [],
            netBudget: 0,
            selectedAdvertiser: undefined,
            selectedAgency: undefined,
            selectedCountry: undefined,
            selectedCurrency: undefined,
            selectedSector: undefined,
            sellingStrategyValue: undefined
        });
        const [isDirectAdvertiser, setIsDirectAdvertiser] = React.useState<boolean>(false);
        const [overrideDiscountRate, setOverrideDiscountRate] = React.useState<boolean>(false);

        const setValues = (value: any, key: string) => {
            setInputValues({ ...inputValues, [key]: value });
            dispatch(setInputValue({ key, value }));
        }

        React.useEffect(() => {
            if (props.briefToLoad) {
                console.log("generalsection brief to load", props.briefToLoad)
            }
        }, [props.briefToLoad])

        React.useEffect(() => {
            // Validate data
            if (userDetails) {
                const valid = (inputValues.netBudget > 0 &&
                    inputValues.selectedCurrency !== undefined &&
                    inputValues.selectedCountry !== undefined &&
                    inputValues.selectedMediaChannels.length > 0 &&
                    inputValues.selectedAdvertiser !== undefined &&
                    (inputValues.selectedAdvertiser !== undefined || (userDetails.agencies.length === 0 && userDetails.advertisers.length > 0)) &&
                    inputValues.selectedProduct !== undefined &&
                    inputValues.selectedSector !== undefined &&
                    inputValues.selectedTarget !== undefined &&
                    inputValues.selectedFormats.length > 0 &&
                    isSellingStrategyValid()
                )

                props.setSectionValidated(props.idx, valid);
            }
        }, [inputValues, userDetails])

        React.useEffect(() => {
            if (userDetails?.agencies.length === 0 && userDetails?.advertisers.length > 0) {
                setIsDirectAdvertiser(true);
            }
        }, [userDetails]);

        React.useEffect(() => {
            (async () => {
                const selectedAdvertiser = inputValues.selectedAdvertiser;
                if (selectedAdvertiser && inputValues.startDate) {
                    let valuesToUpdate: any = {}

                    try {
                        const response = await ApiClientManager.getInstance().getApiClient<ExternalSystemElementApiClient>(ExternalSystemElementApiClient.key).RetrieveSingleDataFromExternalSource<DiscountRate>(DataKey.DiscountRate, localStorage.getItem('token') || "", {
                            endpoint: props.retrieveDiscountRateUrl,
                            method: props.retrieveDiscountRateMethod,
                            body: JSON.parse(StringReplacer.ReplaceInString(JSON.stringify(props.retrieveDiscountRateBody), "{{", "}}", false, inputValues.selectedAdvertiser ? inputValues.selectedAdvertiser.id : "")),
                            parser: props.retrieveDiscountRateParser
                        });

                        if (response.value) {
                            valuesToUpdate.sellingStrategyValue = response.value;
                            dispatch(setInputValue({ key: "sellingStrategyValue", value: response.value }));
                        }
                    }
                    catch (error) {
                        console.error(error);
                        valuesToUpdate.sellingStrategyValue = 0;
                        dispatch(setInputValue({ key: "sellingStrategyValue", value: 0 }));
                    }

                    if (retrievedSectors.length > 0 && selectedAdvertiser.mainSectorId !== "" && selectedAdvertiser.mainSectorId !== "-1") {
                        valuesToUpdate.selectedSector = retrievedSectors.find(sector => sector.id === selectedAdvertiser.mainSectorId)
                        dispatch(setInputValue({ key: "selectedSector", value: valuesToUpdate.selectedSector }));
                    }

                    setInputValues({ ...inputValues, ...valuesToUpdate });
                }
                else if (!selectedAdvertiser)
                    setValues(undefined, "selectedProduct");
            })();
        }, [inputValues.selectedAdvertiser, retrievedSectors, inputValues.startDate]);

        React.useEffect(() => {
            if (inputValues.selectedSector && retrievedMediaChannels && retrievedMediaChannels.length > 0) {
                const retrievedMediaChannelIds = retrievedMediaChannels.map(mediaChannel => mediaChannel.id);
                const body = JsonReplacer.ReplaceInJson(props.retrieveCompetitionCodesBody, props.retrieveCompetitionCodesKeysToReplaceInBody, retrievedMediaChannelIds, [inputValues.selectedSector.id]);
                const dataRetrievalBody: DataRetrieval = {
                    endpoint: props.retrieveCompetitionCodesUrl,
                    method: props.retrieveCompetitionCodesMethod,
                    body: body,
                    parser: props.retrieveCompetitionCodesParser
                }

                dispatch(RetrieveDataFromExternalSource({ dataKey: DataKey.Competition, body: dataRetrievalBody }));
            }
        }, [retrievedMediaChannels, inputValues.selectedSector]);

        React.useEffect(() => {
            if (!inputValues.selectedAgency)
                setValues(undefined, "selectedAdvertiser");
        }, [inputValues.selectedAgency])

        React.useEffect(() => {
            dispatch(setInputValue({ key: "competitionCodes", value: competitionCodes }));
        }, [competitionCodes]);

        const onFormSubmit = (event: any) => {
            event.preventDefault();
            setValues(inputValues.startDate, "startDate");
            setValues(inputValues.endDate, "endDate");
            props.onChangeSection(props.idx + 1);
        }

        const onDirectAdvertiserClick = () => {
            setIsDirectAdvertiser(!isDirectAdvertiser);
        }

        const onOverrideDiscountRateClick = () => {
            setOverrideDiscountRate(!overrideDiscountRate);
        }

        const isSellingStrategyValid = (): boolean => {
            // if fields are not displayed, return OK
            if (!props.requireSellingStrategy) return true

            // fields must be have value
            if (inputValues.sellingStrategyValue === undefined) return false
            // discount between 0 and 100%
            if (inputValues.sellingStrategyType?.id === "discount" && !(inputValues.sellingStrategyValue >= 0 && inputValues.sellingStrategyValue < 100))
                return false

            return true
        }

        const setDates = (startDate: number, endDate: number) => {
            setInputValues({ ...inputValues, startDate: startDate, endDate: endDate });
            dispatch(setInputValue({ key: "startDate", value: startDate }));
            dispatch(setInputValue({ key: "endDate", value: endDate }));
        }

        return (
            <Form onSubmit={onFormSubmit}>
                <Row className="briefInputRow">
                    <Col className="multipleInputsRow">
                        <Input required={userDetails?.qualified && !isDirectAdvertiser} title={t("txt_agency")}>
                            <AgencyPicker
                                allowNew={!userDetails?.qualified}
                                setSelectedAgency={(agency) => setValues(agency, "selectedAgency")}
                                retrieveAgenciesUrl={props.retrieveAgenciesUrl}
                                retrieveAgenciesBody={props.retrieveAgenciesBody}
                                retrieveAgenciesMethod={props.retrieveAgenciesMethod}
                                retrieveAgenciesParser={props.retrieveAgenciesParser}
                                value={inputValues.selectedAgency}
                                filter={userDetails?.agencies}
                                disabled={ isDirectAdvertiser || (userDetails?.agencies && userDetails.agencies.length === 0 && userDetails?.advertisers && userDetails.advertisers.length > 0)}
                            />
                        </Input>
                    </Col>
                    <Col className="multipleInputsRow">
                        <Input
                            required
                            title={t("annoncer")}
                            checkbox={{
                                display: true,
                                title: t("txt_direct_advertiser"),
                                id: 'directAdvertiserCheck',
                                checked: isDirectAdvertiser,
                                onChange: onDirectAdvertiserClick,
                                className: "directAdvertiserCheck"
                            }}
                        >
                            <AdvertiserPicker
                                allowNew={!userDetails?.qualified}
                                // disabled if 
                                // user has no agencies and advertisers and no agency is selected
                                // user has no agencies and no agency is selected
                                // should not be disabled if user has advertisers and no agency
                                disabled={
                                    userDetails && (
                                        (!isDirectAdvertiser && userDetails.agencies.length === 0 && userDetails.advertisers.length === 0 && !inputValues.selectedAgency) ||
                                        (userDetails.agencies.length > 0 && !inputValues.selectedAgency)
                                    )
                                }
                                setSelectedAdvertiser={(advertiser) => setValues(advertiser, "selectedAdvertiser")}
                                retrieveAdvertisersUrl={
                                    (isDirectAdvertiser || inputValues.selectedAgency) && props.retrieveAdvertisersMethod === "get"
                                        ? StringReplacer.ReplaceInString(props.retrieveAdvertisersUrl, "{{", "}}", false, inputValues.selectedAgency ? inputValues.selectedAgency.id : "-1")
                                        : props.retrieveAdvertisersUrl
                                }
                                retrieveAdvertisersBody={props.retrieveAdvertisersBody}
                                retrieveAdvertisersMethod={props.retrieveAdvertisersMethod}
                                retrieveAdvertisersParser={props.retrieveAdvertisersParser}
                                value={inputValues.selectedAdvertiser}
                                filter={userDetails?.advertisers}
                            />
                        </Input>
                    </Col>
                </Row>
                <Row className="briefInputRow">
                    <Col className="multipleInputsRow">
                        <Input required title={t("product")}>
                            <ProductPicker
                                allowNew={!userDetails?.qualified}
                                disabled={!inputValues.selectedAdvertiser}
                                setSelectedProduct={(product) => {
                                    setValues(product, "selectedProduct");
                                }}
                                retrieveProductsUrl={
                                    inputValues.selectedAdvertiser && props.retrieveProductsMethod === "get"
                                        ? StringReplacer.ReplaceInString(props.retrieveProductsUrl, "{{", "}}", false, inputValues.selectedAdvertiser.id)
                                        : props.retrieveProductsUrl
                                }
                                retrieveProductsBody={
                                    inputValues.selectedAdvertiser && props.retrieveProductsMethod === "post"
                                        ? JSON.parse(StringReplacer.ReplaceInString(JSON.stringify(props.retrieveProductsBody), "{{", "}}", false, inputValues.selectedAdvertiser ? inputValues.selectedAdvertiser.id : ""))
                                        : props.retrieveProductsBody
                                }
                                retrieveProductsMethod={props.retrieveProductsMethod}
                                retrieveProductsParser={props.retrieveProductsParser}
                                value={inputValues.selectedProduct}
                            />
                        </Input>
                    </Col>
                    <Col className="multipleInputsRow">
                        <Input required title={t("txt_sector")}>
                            <SectorPicker
                                setSelectedSector={(sector) => setValues(sector, "selectedSector")}
                                retrieveSectorsParser={props.retrieveSectorsParser}
                                retrieveSectorsBody={props.retrieveSectorsBody}
                                retrieveSectorsUrl={props.retrieveSectorsUrl}
                                retrieveSectorsMethod={props.retrieveSectorsMethod}
                                value={inputValues.selectedSector}
                            />
                        </Input>
                    </Col>
                </Row>
                <Row className="briefInputRow">
                    {props.useCountryIdToRetrieveMediaChannels &&
                        <Col className="multipleInputsRow">
                            <Input required title={t("txt_country_label")}>
                                <CountryPicker
                                    setSelectedCountry={(country) => setValues(country, "selectedCountry")}
                                    retrieveCountriesUrl={props.retrieveCountriesUrl}
                                    retrieveCountriesBody={props.retrieveCountriesBody}
                                    retrieveCountriesMethod={props.retrieveCountriesMethod}
                                    retrieveCountriesParser={props.retrieveCountriesParser}
                                    value={inputValues.selectedCountry}
                                />
                            </Input>
                        </Col>
                    }
                    <Col className="multipleInputsRow">
                        <Input required title={t("supports")}>
                            <MediaChannelPicker
                                disabled={props.useCountryIdToRetrieveMediaChannels && !inputValues.selectedCountry}
                                setSelectedMediaChannels={(mediaChannels) => setValues(mediaChannels, "selectedMediaChannels")}
                                retrieveMediaChannelsUrl={props.retrieveMediaChannelsUrl}
                                retrieveMediaChannelsBody={!props.useCountryIdToRetrieveMediaChannels ? props.retrieveMediaChannelsBody : JSON.parse(StringReplacer.ReplaceInString(JSON.stringify(props.retrieveMediaChannelsBody), "{{", "}}", false, inputValues.selectedCountry ? inputValues.selectedCountry.id : ""))}
                                retrieveMediaChannelsMethod={props.retrieveMediaChannelsMethod}
                                retrieveMediaChannelsParser={props.retrieveMediaChannelsParser}
                                value={inputValues.selectedMediaChannels}
                            />
                        </Input>
                    </Col>
                </Row>
                <Row className="briefInputRow">
                    <Col className="multipleInputsRow">
                        <Input required title={t("txt_target")}>
                            <TargetPicker
                                setSelectedTarget={(target) => setValues(target, "selectedTarget")}
                                retrieveTargetsParser={props.retrieveTargetsParser}
                                retrieveTargetsBody={props.retrieveTargetsBody}
                                retrieveTargetsUrl={props.retrieveTargetsUrl}
                                retrieveTargetsMethod={props.retrieveTargetsMethod}
                                value={inputValues.selectedTarget}
                            />
                        </Input>
                    </Col>
                    <Col className="multipleInputsRow">
                        <Input required title={t("txt_format")}>
                            <FormatPicker
                                required
                                setInputValue={setValues}
                                availableFormats={props.availableFormats}
                                values={inputValues.selectedFormats}
                            />
                        </Input>
                    </Col>
                </Row>
                <Row className="briefInputRow">
                    <Input required title={t("txt_broadcast_period")}>
                        <PeriodPicker
                            startDate={inputValues.startDate}
                            endDate={inputValues.endDate}
                            setDates={setDates}
                            setStartDate={(date) => setValues(date, "startDate")}
                            setEndDate={(date) => setValues(date, "endDate")}
                            minDate={dateObjectToNumber(tomorrow)}
                        />
                    </Input>
                </Row>
                <Row className="briefInputRow">
                    <Input title={t("txt_broadcast_days")}>
                        <DaysPicker
                            selectedDays={inputValues.selectedDays}
                            setSelectedDays={(days) => setValues(days, "selectedDays")} />
                    </Input>
                </Row>
                <Row className="briefInputRow">
                    <Col className="multipleInputsRow">
                        <Input required title={t("txt_currency")}>
                            <CurrencyPicker
                                setSelectedCurrency={(currency) => setValues(currency, "selectedCurrency")}
                                retrieveCurrenciesUrl={props.retrieveCurrenciesUrl}
                                retrieveCurrenciesBody={props.retrieveCurrenciesBody}
                                retrieveCurrenciesMethod={props.retrieveCurrenciesMethod}
                                retrieveCurrenciesParser={props.retrieveCurrenciesParser}
                                value={inputValues.selectedCurrency}
                            />
                        </Input>
                    </Col>
                    <Col className="multipleInputsRow">
                        <Input required title={t("txt_net_budget")}>
                            <DefaultInput
                                onChange={(value: any) => setValues(parseFloat(value), "netBudget")}
                                type="currency"
                                iconKey="currency"
                                placeholder={t("txt_net_budget")}
                            />
                        </Input>
                    </Col>
                    {props.requireSellingStrategy && !userDetails?.external &&
                            <Col className="multipleInputsRow">
                                <Input
                                    required
                                    title={t("txt_method_discount")}
                                    checkbox={{
                                        display: true,
                                        title: t("txt_custom_rate"),
                                        id: 'customRateCheck',
                                        checked: overrideDiscountRate,
                                        onChange: onOverrideDiscountRateClick,
                                        className: "directAdvertiserCheck"
                                    }}
                                >
                                    <DiscountRateInput
                                        setValues={setValues}
                                        disabled={!overrideDiscountRate}
                                        value={inputValues.sellingStrategyValue ? inputValues.sellingStrategyValue : 0}
                                        advertiser={inputValues.selectedAdvertiser}
                                        startDate={inputValues.startDate}
                                    />
                                </Input>
                            </Col>
                    }
                </Row>
                <Col className="text-center">
                    <Button
                        type="submit"
                        className="sectionContinueButton"
                        variant="dark"
                        disabled={props.nextButtonDisabled}>
                        {t("next_button")}
                    </Button>
                </Col>
            </Form>
        );
    }
}