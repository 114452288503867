import { ApiResponse } from "../dto/ApiResponse";
import { resolveUrl } from "../utils/UrlResolver";
import { ApiClient } from "./ApiClient";

export class AuthApiClient extends ApiClient {
    public static key = "AuthApiClient";

    public async Login(email: string, password: string, clientId: string): Promise<string> {
        return (await this.Post<ApiResponse<{ token: string }>>(resolveUrl(`${this.baseUrl}`, `/auth/login`), { email, password, clientId })).data.token;
    }

    public async Register(firstName: string, lastName: string, email: string, password: string, clientId: string, locale: string, company: string, country: string, phone?: string, position?: string): Promise<void> {
        await this.Post<ApiResponse<void>>(resolveUrl(`${this.baseUrl}`, `/auth/register`), { firstName, lastName, email, password, clientId, locale, company, country, phone, position });
    }

    public async ForgotPassword(email: string, clientId: string, locale: string): Promise<void> {
        await this.Post<ApiResponse<void>>(resolveUrl(`${this.baseUrl}`, `/auth/forgotPassword`), { email: email, clientId: clientId, locale: locale });
    }

    public async ResetPassword(password: string, token: string): Promise<void> {
        await this.Post<ApiResponse<void>>(resolveUrl(`${this.baseUrl}`, `/auth/resetPassword`), { password: password, token: token });
    }

    public async verifyEmail(token: string): Promise<void> {
        await this.Post<ApiResponse<void>>(resolveUrl(`${this.baseUrl}`, `/auth/verify`), { token : token });
    }


}