import { ApiResponse } from "../dto/ApiResponse";
import { ExternalSystemElement } from "../dto/ExternalSystemElement";
import { DataRetrieval } from "../dto/ExternalSystemRetrieveBody";
import { resolveUrl } from "../utils/UrlResolver";
import { ApiClient } from "./ApiClient";

export class ExternalSystemElementApiClient extends ApiClient {
    public static key = "ExternalSystemElementApiClient";

    public async RetrieveDataFromExternalSource<T extends ExternalSystemElement>(dataKey: string, accessToken: string, body?: any): Promise<T[]> {
        return (await this.Post<ApiResponse<T[]>>(resolveUrl(`${this.baseUrl}${dataKey}`, `/retrieve`), body, accessToken)).data;
    }

    public async RetrieveSingleDataFromExternalSource<T>(dataKey: string, accessToken: string, body?: DataRetrieval): Promise<T> {
        return (await this.Post<ApiResponse<T>>(resolveUrl(`${this.baseUrl}${dataKey}`, `/retrieve`), body, accessToken)).data;
    }
}