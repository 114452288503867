import { Col, Form, InputGroup, Row } from "react-bootstrap";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './PeriodPicker.css';
import moment, { Moment } from "moment";
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import React from "react";
import { useAppSelector } from "../../Redux/hooks";
import { DefaultInput } from "./DefaultInput";

interface PeriodPickerProps {
    setStartDate: (date: number) => void;
    setEndDate: (date: number) => void;
    setDates?: (startDate: number, endDate: number) => void;
    startDate?: number;
    endDate?: number;
    monoCol?: boolean;
    required?: boolean;
    maxDate?: number;
    minDate?: number;
}

const transformNumberToDate = (dateNumber: number | undefined): Date | null => {
    if (!dateNumber) {
        return null;
    }
    // Convert the number to a string
    const dateString = dateNumber.toString();

    // Check if the string has the correct length
    if (dateString.length !== 8) {
        console.error("Invalid date number. It should be in YYYYMMDD format.");
        return null;
    }

    // Insert dashes to form 'YYYY-MM-DD' format
    const formattedDateString = `${dateString.substring(0, 4)}-${dateString.substring(4, 6)}-${dateString.substring(6, 8)}`;

    // Create a new Date object by parsing the formatted string
    const date = new Date(formattedDateString);

    // Check if the Date object is valid
    if (isNaN(date.getTime())) {
        console.error("Invalid date.");
        return null;
    }

    return date;
}

export const PeriodPicker = (props: PeriodPickerProps) => {
    const locale = useAppSelector(state => state.locale.locale);
    moment.locale(locale);
    const [startDate, setStartDate] = React.useState<Date>(transformNumberToDate(props.startDate) || new Date());
    const [endDate, setEndDate] = React.useState<Date>(transformNumberToDate(props.endDate) || new Date());
    const [maxDate] = React.useState<Date | undefined>(props.maxDate ? (transformNumberToDate(props.maxDate) || undefined) : undefined);
    const [minDate] = React.useState<Date | undefined>(props.minDate ? (transformNumberToDate(props.minDate) || undefined) : undefined);

    const handleCallback = (start: Moment, end: Moment, label: any) => {
        const tmpStart = start.toDate();
        const tmpEnd = end.toDate();

        setStartDate(tmpStart);
        setEndDate(tmpEnd);

        if (props.setDates)
            props.setDates(tmpStart.getFullYear() * 10000 + (tmpStart.getMonth() + 1) * 100 + tmpStart.getDate(), tmpEnd.getFullYear() * 10000 + (tmpEnd.getMonth() + 1) * 100 + tmpEnd.getDate());
        else {
            props.setStartDate(tmpStart.getFullYear() * 10000 + (tmpStart.getMonth() + 1) * 100 + tmpStart.getDate());
            props.setEndDate(tmpEnd.getFullYear() * 10000 + (tmpEnd.getMonth() + 1) * 100 + tmpEnd.getDate());
        }
    }

    return (
        <DateRangePicker onCallback={handleCallback} initialSettings={{ maxDate: maxDate, minDate: minDate, startDate: startDate, endDate: endDate, autoApply: true, applyClass: "customDateRangePickerClass" }}>
            {props.monoCol ?
                <Row>
                    <Col>
                        <InputGroup>
                            <Form.Control required={props.required} readOnly value={moment(startDate).format('L') + " - " + moment(endDate).format('L')} className="dateInput" />
                        </InputGroup>
                    </Col>

                </Row>
                :
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="startDate">
                            <DefaultInput
                                required={props.required}
                                readOnly
                                value={moment(startDate).format('L')}
                                type="text"
                                placeholder="Date"
                                iconKey="calendar"
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="endDate">
                            <DefaultInput
                                required={props.required}
                                readOnly
                                value={moment(endDate).format('L')}
                                type="text"
                                placeholder="Date"
                                iconKey="calendar"
                            />
                        </Form.Group>
                    </Col>
                </Row>
            }


        </DateRangePicker>
    );
}