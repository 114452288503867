import { GetClientConfigParams } from "../../Redux/Reducers/clientSlice";
import { ApiResponse } from "../dto/ApiResponse";
import { ClientAdditionalData, ClientConfig } from "../dto/ClientConfig";
import { resolveUrl } from "../utils/UrlResolver";
import { ApiClient } from "./ApiClient";

export class ClientApiClient extends ApiClient {
    public static key="ClientApiClient";

    public async GetClientConfig(params: GetClientConfigParams): Promise<ClientConfig> {
        return (await this.Post<ApiResponse<ClientConfig>>(resolveUrl(`${this.baseUrl}`, `/superClient/getConfig`), params)).data;
    }

    public async GetClientAdditionalData(clientId: string): Promise<ClientAdditionalData> {
        return (await this.Get<ApiResponse<ClientAdditionalData>>(resolveUrl(`${this.baseUrl}`, `/superClient/additionalData/${clientId}`))).data;
    }
}