import DataTable, { TableColumn } from "react-data-table-component";
import { t } from "i18next";
import React from "react";
import { PulseLoader } from "react-spinners";

interface BasicTableProps<T> {
    data: T[];
    pagination?: { itemsPerPage: number };
    columns: TableColumn<T>[];
    sortOptions?: {
        defaultSortColumnIdx: number;
        defaultSortDirection: "asc" | "desc";
    }
    // key field must be in T
    keyField: string;
    resetPaginationToggle?: boolean;
}

export function BasicTable<T>(props: BasicTableProps<T>) {
    const [loading, setLoading] = React.useState(true);

    const onChangePage = (_page: number, _totalRows: number) => {
        // Scroll to top of the page
        window.scrollTo(0, 0);
    }

    React.useEffect(() => {
        if (props.data.length > 0) {
            setLoading(false);
        }
    }, [props.data])

    return (
        <>
            {loading ?
                <div className="titlePageLoader">
                    <PulseLoader aria-label="Loading..." />
                </div>
                :
                <DataTable<T>
                    pagination={props.pagination !== undefined}
                    paginationPerPage={props.pagination?.itemsPerPage}
                    paginationComponentOptions={{
                        rowsPerPageText: t("txt_rows_per_page"),
                        rangeSeparatorText: t("txt_of"),
                    }}
                    columns={props.columns}
                    data={props.data}
                    onChangePage={onChangePage}
                    responsive
                    striped
                    highlightOnHover
                    defaultSortFieldId={props.sortOptions?.defaultSortColumnIdx}
                    defaultSortAsc={props.sortOptions?.defaultSortDirection === "asc"}
                    paginationResetDefaultPage={props.resetPaginationToggle}
                />
            }
        </>
    );
}