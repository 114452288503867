import React from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import './AwaitAllocationResults.css';
import { Col, Container, Row } from "react-bootstrap";
import { useAppSelector } from "../Redux/hooks";
import { PulseLoader } from "react-spinners";
import { useLocation } from "react-router-dom";
import { ClientIdRedirect, useClientIdNavigate } from "../Router";
import { AllocationApiClient } from "../Business/api/AllocationApiClient";
import { ApiClientManager } from "../Business/api/ApiClientManager";
import { socket } from "../socket";
import { useTranslation } from "react-i18next";
import { BriefApiClient } from "../Business/api/BriefApiClient";


export const AwaitAllocationResults = () => {
    const { t } = useTranslation();
    const navigate = useClientIdNavigate();
    const { state } = useLocation();
    const clientConfig = useAppSelector(state => state.clientConfig.config);
    const steps = [
        { label: "txt_allocation_handshake" },
        { label: "txt_inventory_updating" },
        { label: "txt_allocation_in_progress" },
        { label: "txt_allocation_done" }
    ]
    const [currentStep, setCurrentStep] = React.useState<number>(0);
    const [progressPercent, setProgressPercent] = React.useState<number>(0);
    const [errorOccured, setErrorOccured] = React.useState<boolean>(false);
    const [dataToPass, setDataToPass] = React.useState<any>(null);
    const [briefId, setBriefId] = React.useState<string>("");

    React.useEffect(() => {
        setProgressPercent((currentStep + 1) * 100 / steps.length);
    }, [currentStep, steps.length])

    React.useEffect(() => {
        function onSocketResults(value: any) {
            if (value.data.success) {
                setCurrentStep(3);
                console.log(value.data)

                if (value.data.data.length > 0) {
                    console.log(value.data.data[0]);
                    setDataToPass(value.data.data[0]);
                }
                else {
                    console.log("success but no solutions");
                    setErrorOccured(true);
                }
            }
            else {
                console.log("error during allocation", value.data);
                setErrorOccured(true);
            }
            //navigate("/allocatorSolutions");
        }

        socket.on("allocationResults", onSocketResults);
        
        socket.connect();

        return () => {
            socket.off("allocationResults", onSocketResults);
            socket.disconnect();
        };
    }, []);

    React.useEffect(() => {
        if (state) {
            const token = localStorage.getItem("token");

            if (token) {
                const apiClient = ApiClientManager.getInstance().getApiClient<AllocationApiClient>(AllocationApiClient.key);
                const briefApiClient = ApiClientManager.getInstance().getApiClient<BriefApiClient>(BriefApiClient.key);

                briefApiClient.CreateBrief(state.jamDto, state.selectedBrief, token).then((createBriefRes) => {
                    if (createBriefRes.success) {
                        console.log(createBriefRes.data.brief._id);
                        const briefId = createBriefRes.data.brief._id;

                        setBriefId(briefId);
                        
                        apiClient.SendBrief(token, state.brief).then((sendBriefRes) => {
                            if (sendBriefRes.success) {
                                console.log(sendBriefRes.data);
                                const sessionRunId = sendBriefRes.data.session_run_id;
                                const sessionId = sendBriefRes.data.session_id;

                                socket.emit("authenticate", { accessToken: token, sessionRunId: sessionRunId });

                                briefApiClient.AddAllocInfos(briefId, sessionId, sessionRunId, token).then((addAllocInfosRes) => {
                                    console.log("brief updated in db")
                                    console.log(addAllocInfosRes);
                                }).catch((err) => {
                                    console.log("add alloc infos error", err);
                                    setErrorOccured(true);
                                });

                                setCurrentStep(1);

                                // Save ids to brief

                                apiClient.UpdateScreens(token, state.brief, sessionId, sessionRunId).then((updateScreensRes) => {
                                    setCurrentStep(2);
                                    console.log(updateScreensRes);
        
                                    apiClient.StartAllocation(token, sessionRunId).then((startAllocRes) => {
                                        console.log(startAllocRes);
                                    }).catch((err) => {
                                        console.log("start allocation error", err);
                                        setErrorOccured(true);
                                    });
        
                                }).catch((err) => {
                                    console.log("update screens error", err);
                                    setErrorOccured(true);
                                });
                            }
                            else {
                                console.log("send brief error", sendBriefRes);
                                setErrorOccured(true);
                            }
                        }).catch((err) => {
                            console.log("send brief error", err);
                            setErrorOccured(true);
                        })
                    }
                    else {
                        console.log("create brief error", createBriefRes);
                        setErrorOccured(true);
                    }
                }).catch((err) => {
                    console.log("create brief error", err);
                    setErrorOccured(true);
                });
            }
        }
        else {
            navigate("/");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    return (
        <div className="awaitAllocationResultsContainer">
            {dataToPass ?
                <ClientIdRedirect path="/allocatorSolutions" options={{ state: { solutions: dataToPass, briefId: briefId } }} />
                :
                <Container className="innerAwaitAllocationResultsContainer">
                    <Row>
                        <Col style={{ marginBottom: "5vh" }} className="textAwaitSolutions" md={{ offset: 2, span: 8 }}>
                            <h1><b>Allocation en cours</b></h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <ProgressBar
                                percent={progressPercent}
                                filledBackground={`linear-gradient(to right, ${errorOccured ? "#ff8282" : clientConfig?.secondaryColor}, ${errorOccured ? "#ff0000" : clientConfig?.primaryColor})`}
                            >
                                {steps.map((_step, index) => (
                                    <Step key={index}>
                                        {({ accomplished, index }) => (
                                            <div
                                                className={`indexedStep ${accomplished ? "accomplished" : null} ${errorOccured ? "errorOccured" : null}`}
                                            >
                                                {index + 1}
                                            </div>
                                        )}
                                    </Step>
                                ))}
                            </ProgressBar>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ marginTop: "2vh" }} className="textAwaitSolutions" md={{ offset: 2, span: 8 }}>
                            <p><b>{t(steps[currentStep].label)}</b></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ marginTop: "5vh" }} className="textAwaitSolutions" md={{ offset: 2, span: 8 }}>
                            <PulseLoader color={clientConfig?.primaryColor} />
                        </Col>
                    </Row>
                </Container>
            }
        </div>
    );
}