import { Route, RouterProvider, Routes, useNavigate, createBrowserRouter, RouteObject, Navigate, Link } from "react-router-dom";
import { Brief } from "./Pages/Brief";
import { Home } from "./Pages/Home";
import { Page } from "./Pages/Page";
import { Register } from "./Pages/Register";
import { SelectBrief } from "./Pages/SelectBrief";
import { TestSocket } from "./Pages/TestSocket";
import { AllocatorSolutions } from "./Pages/AllocatorSolutions";
import { AwaitAllocationResults } from "./Pages/AwaitAllocationResults";
import { UserList } from "./Pages/UserList";
import { UserDetailsComponent } from "./Pages/UserDetails";
import { ForgotPassword } from "./Pages/ForgotPassword";
import { ResetPassword } from "./Pages/ResetPassword";
import { Success } from "./Pages/Success";
import { RedirectPage } from "./Pages/RedirectPage";
import { AllocationHistory } from "./Pages/AllocationHistory";
import { LegalNotices } from "./Pages/LegalNotices";
import { useAppSelector } from "./Redux/hooks";

export enum RoutingMode {
    DOMAIN = "DOMAIN",
    CLIENT_ID = "CLIENT_ID"
}

export const useClientIdNavigate = () => {
    const navigate = useNavigate();
    const clientId = useAppSelector(state => state.clientConfig.config?.id);
    const routingMode: RoutingMode = process.env.REACT_APP_ROUTING_MODE as RoutingMode;

    // Wrapper function to navigate with clientId prefix
    const navigateWithClientId = (path: any, options?: any) => {
        if (Number.isInteger(path))
            navigate(path, options);
        else if (routingMode === RoutingMode.CLIENT_ID)
            navigate(`/${clientId}${path}`, options);
        else
            navigate(path, options);
    };

    return navigateWithClientId;
};

export const ClientIdRedirect = ({path, options}: {path: string, options?: any}) => {
    const clientId = useAppSelector(state => state.clientConfig.config?.id);
    const routingMode: RoutingMode = process.env.REACT_APP_ROUTING_MODE as RoutingMode;

    if (routingMode === RoutingMode.CLIENT_ID)
        return (
            <Navigate to={`/${clientId}${path}`} {...options} />
        );
    else
        return (
            <Navigate to={path} {...options} />
        );
}

export const ClientIdLink = ({to, children, props}: {to: string, children: any, props?: any}) => {
    const clientId = useAppSelector(state => state.clientConfig.config?.id);
    const routingMode: RoutingMode = process.env.REACT_APP_ROUTING_MODE as RoutingMode;

    if (routingMode === RoutingMode.CLIENT_ID)
        return (
            <Link to={`/${clientId}${to}`} {...props}>{children}</Link>
        );
    else
        return (
            <Link to={to} {...props}>{children}</Link>
        );
}

const ClientRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Page title="" withoutMarginAndHeader children={<Home />} />} />
            <Route path="/register" element={<Page title="" withoutMarginAndHeader children={<Register />} />} />
            <Route path="/success/:type/:token" element={<Page title="" withoutMarginAndHeader children={<Success />} />} />
            <Route path="/forgotPassword" element={<Page title="" withoutMarginAndHeader children={<ForgotPassword />} />} />
            <Route path="/resetPassword/:token" element={<Page title="" withoutMarginAndHeader children={<ResetPassword />} />} />
            <Route path="/selectBrief" element={<SelectBrief />} />
            <Route path="/brief" element={<Brief />} />
            <Route path="/awaitResults" element={<Page category="" title="" authenticated children={<AwaitAllocationResults />} />} />
            <Route path="/testSocket" element={<Page category={"txt_brief"} title={"txt_input_request"} authenticated children={<TestSocket />} />} />
            <Route path="/allocatorSolutions/:sessionRunId?" element={<AllocatorSolutions />} />
            <Route path="/userList" element={<Page category={"txt_user"} title={"txt_user_list"} authenticated admin children={<UserList />} />} />
            <Route path="/userDetails/:id" element={<Page category={"txt_user"} title={"txt_details"} authenticated admin children={<UserDetailsComponent />} />} />
            <Route path="/redirect" element={<Page category="" title="" children={<RedirectPage />} />} />
            <Route path="/history" element={<Page category="txt_history" title="txt_requests_history" authenticated children={<AllocationHistory />} />} />
            <Route path="/legalNotice" element={<Page category="" title="" children={<LegalNotices />} />} />
        </Routes>
    );
};

export const JamRouter = () => {
    const routingMode: RoutingMode = process.env.REACT_APP_ROUTING_MODE as RoutingMode;

    const routes: RouteObject[] = [
        {
            path: routingMode === RoutingMode.DOMAIN ? "*" : "/:clientId/*",
            element: <ClientRoutes />,
            shouldRevalidate: () => false
        }
    ];

    return (
        <RouterProvider router={createBrowserRouter(routes)} />
    );
};