import React from "react";
import "./FormatPicker.css";
import { useAppDispatch } from "../../Redux/hooks";
import { setInputValue } from "../../Redux/Reducers/briefSlice";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { ChoicePicker, ChoicePickerOption } from "./ChoicePicker";

export interface grpPerFormat {
    [key: string]: any;
    format: string;
    grpRepartitionPercent: number;
}

export interface FormatPickerProps {
    required?: boolean;
    values: number[];
    setInputValue: (value: any, key: string) => void;
    availableFormats: number[];
}

export const FormatPicker = (props: FormatPickerProps) => {
    const { t } = useTranslation();
    const [formats, setFormats] = React.useState<grpPerFormat[]>([]);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(setInputValue({ key: "grpPerFormat", value: formats }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formats]);

    return (
                <ChoicePicker
                    setSelectedData={(selected: ChoicePickerOption[]) => {
                        props.setInputValue(selected.map((s) => Number.parseInt(s.id)), "selectedFormats");
                        setFormats(selected.map((s) => ({ format: s.id, grpRepartitionPercent: 100 / selected.length })))
                    }}
                    placeholder={t("txt_format")}
                    data={props.availableFormats.map((f) => ({ name: f.toString(), id: f.toString() }))}
                    multiple={true}
                    icon={faClock}
                    required={props.required}
                    controlId={"durationPicker"}
                    value={props.values.map((v) => ({ name: v.toString(), id: v.toString() }))}
                />
    );

}