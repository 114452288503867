import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Form, Navbar, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useClientIdNavigate } from "../Router";
import './UserDetails.css';
import { Input } from "../Components/Inputs/Input";
import { DefaultInput } from "../Components/Inputs/DefaultInput";
import React from "react";
import { UserDetails, UserRoles } from "../Business/dto/UserDetails";
import { useAppSelector } from "../Redux/hooks";
import { AgencyPicker } from "../Components/Inputs/AgencyPicker";
import { AdvertiserPicker } from "../Components/Inputs/AdvertiserPicker";
import { StringReplacer } from "../Business/utils/StringReplacer";
import { ApiClientManager } from "../Business/api/ApiClientManager";
import { UserApiClient } from "../Business/api/UserApiClient";
import { toast } from "react-toastify";

const duplicate = (user: any) => {
    return JSON.parse(JSON.stringify(user));
}

export const UserDetailsComponent = () => {
    const navigate = useClientIdNavigate();
    const { t } = useTranslation();
    const { state } = useLocation();
    const locale = useAppSelector(state => state.locale.locale);
    const clientConfig = useAppSelector(state => state.clientConfig.config);
    const [duplicatedUser, setDuplicatedUser] = React.useState<UserDetails>(duplicate(state.userDetails));
    const [configProps, setConfigProps] = React.useState<any>(undefined);

    React.useEffect(() => {
        setConfigProps(clientConfig?.briefConfigs[0].brief.sections[0].props);
    }, [clientConfig])

    const onBackButton = () => {
        navigate(-1);
    }

    const canUserQualify = (): boolean => {
        // If internal, only needs accountAdminEmail
        if (!duplicatedUser.external && duplicatedUser.accountAdminEmail)
            return true

        // If external, needs accountAdminEmail and at least one agency or advertiser
        if (duplicatedUser.accountAdminEmail && ((duplicatedUser.agencies && duplicatedUser.agencies.length > 0) || (duplicatedUser.advertisers && duplicatedUser.advertisers.length > 0)))
            return true
        return false
    }

    const onFormSubmit = (event: any) => {
        event.preventDefault();
        
        if (duplicatedUser.qualified && !canUserQualify()) {
            toast.error(t("txt_qualify_error"));
            return;
        }

        ApiClientManager.getInstance().getApiClient<UserApiClient>(UserApiClient.key).UpdateUserDetails(localStorage.getItem('token') || "", duplicatedUser, locale).then(() => {
            toast.success(t("txt_user_updated"));
        }).catch((error) => {
            console.log(error);
            toast.error(t("txt_error_occurred"));
        });
    }

    const onInputChange = (value: any, key: keyof UserDetails) => {
        let newUser = duplicate(duplicatedUser);
        newUser[key] = value;
        setDuplicatedUser(newUser);
    }

    return (
        <Form onSubmit={onFormSubmit}>
            {configProps && <>
                <Row style={{ width: "40%", marginBottom: "1vh", minWidth: "350px" }}>
                    <Input title={t("txt_user_max_alloc")}>
                        <DefaultInput
                            type="number"
                            iconKey="count"
                            value={duplicatedUser.maxAllocationPerDay}
                            onChange={(value) => onInputChange(value, "maxAllocationPerDay")}
                            placeholder={t("txt_user_max_alloc")}
                        />
                    </Input>
                </Row>
                <Row style={{ width: "40%", marginBottom: "1vh", minWidth: "350px" }}>
                    <Input title={t("txt_user_mail_supervisor")}>
                        <DefaultInput
                            type="email"
                            iconKey="email"
                            value={duplicatedUser.accountAdminEmail}
                            onChange={(value) => onInputChange(value, "accountAdminEmail")}
                            placeholder={t("txt_user_mail_supervisor")}
                        />
                    </Input>
                </Row>
                <Row style={{ width: "40%", minWidth: "350px" }}>
                    <Input title={t("txt_agencies")}>
                        <AgencyPicker
                            multiple
                            values={duplicatedUser.agencies}
                            setSelectedAgencies={(agencies) => onInputChange(agencies, "agencies")}
                            retrieveAgenciesUrl={configProps.retrieveAgenciesUrl}
                            retrieveAgenciesBody={configProps.retrieveAgenciesBody}
                            retrieveAgenciesMethod={configProps.retrieveAgenciesMethod}
                            retrieveAgenciesParser={configProps.retrieveAgenciesParser}
                        />
                    </Input>
                </Row>
                <Row style={{ width: "40%", minWidth: "350px" }}>
                    <Input title={t("txt_advertisers")}>
                        <AdvertiserPicker
                            multiple
                            values={duplicatedUser.advertisers as any}
                            setSelectedAdvertisers={(advertisers) => onInputChange(advertisers, "advertisers")}
                            retrieveAdvertisersUrl={
                                configProps.retrieveAdvertisersMethod === "get"
                                    ? StringReplacer.ReplaceInString(configProps.retrieveAdvertisersUrl, 
                                        "{{", "}}",
                                        false,
                                        (duplicatedUser.agencies && duplicatedUser.agencies.length > 0) ? duplicatedUser.agencies.map(agency => agency.id).join(',') : "-1"
                                    )
                                    : configProps.retrieveAdvertisersUrl
                            }
                            retrieveAdvertisersBody={configProps.retrieveAdvertisersBody}
                            retrieveAdvertisersMethod={configProps.retrieveAdvertisersMethod}
                            retrieveAdvertisersParser={configProps.retrieveAdvertisersParser}
                        />
                    </Input>
                </Row>
                <Row style={{ width: "40%", minWidth: "350px" }}>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            id="userActiveCheckbox"
                            className="userDetailsCheckbox"
                            checked={duplicatedUser.active}
                            onChange={(event) => onInputChange(event.target.checked, "active")}
                            label={t("active")} />
                    </Col>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            id="userQualifiedCheckbox"
                            className="userDetailsCheckbox"
                            checked={duplicatedUser.qualified}
                            onChange={(event) => onInputChange(event.target.checked, "qualified")}
                            label={t("txt_confirmed")} />
                    </Col>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            id="userIsAdminCheckbox"
                            className="userDetailsCheckbox"
                            checked={duplicatedUser.roles.includes(UserRoles.ADMIN)}
                            onChange={(event) => {
                                let newUser = duplicate(duplicatedUser);
                                if (event.target.checked) {
                                    newUser.roles.push(UserRoles.ADMIN);
                                }
                                else {
                                    newUser.roles = newUser.roles.filter((role: UserRoles) => role !== UserRoles.ADMIN);
                                }
                                setDuplicatedUser(newUser);
                            }}
                            label={t("txt_administrator")} />
                    </Col>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            id="userExternalCheckbox"
                            className="userDetailsCheckbox"
                            checked={duplicatedUser.external}
                            onChange={(event) => onInputChange(event.target.checked, "external")}
                            label={t("external")} />
                    </Col>
                </Row>
                <Navbar fixed="bottom" className="footer">
                    <Container>
                        <Col className="justify-content-between" style={{ display: 'flex' }} md={12} sm={12} xs={12}>
                            <Button onClick={onBackButton} className="userDetailsBackButton" variant="light"><FontAwesomeIcon icon={faArrowLeft} /> {t("cancel")}</Button>
                            <Button type="submit" className="userDetailsValidateButton" variant="dark">{t("validate")}</Button>
                        </Col>
                    </Container>
                </Navbar>
            </>
            }
        </Form>
    );
}