import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import './Input.css';

export interface InputProps {
    title: string;
    children: any;
    required?: boolean;
    checkbox?: { display: boolean, id: string, title: string, checked: boolean, className?: string, onChange: (checked: boolean) => void };
}

export class Input extends React.Component<InputProps> {
    renderChildren() {
        return React.Children.map(this.props.children, (child) => {
            return React.cloneElement(child, { required: this.props.required });
        });
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <b className="inputTitleText">
                            {this.props.title}
                            {this.props.checkbox && this.props.checkbox.display &&
                                <Form.Check
                                    type="checkbox"
                                    inline
                                    id={this.props.checkbox.id}
                                    label={this.props.checkbox.title}
                                    checked={this.props.checkbox.checked}
                                    onChange={(e: any) => this.props.checkbox?.onChange(e.target.checked)}
                                    className={`inputTitleTextCheckbox ${this.props.checkbox.className}`}
                                />
                            }
                            {this.props.required ? <span className="requiredStar"> *</span> : ""}
                        </b>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.renderChildren()}
                    </Col>
                </Row>
            </Container>
        );
    }
};