import React from "react";
import { ApiClientManager } from "../Business/api/ApiClientManager";
import { ClientApiClient } from "../Business/api/ClientApiClient";
import { ClientAdditionalData } from "../Business/dto/ClientConfig";
import { useAppSelector } from "../Redux/hooks";

export const LegalNotices = () => {
    const clientId = useAppSelector(state => state.clientConfig.config?.id);
    const [additionalData, setAdditionalData] = React.useState<ClientAdditionalData>();
    
    React.useEffect(() => {
        const fetchAdditionalData = async () => {
            if (clientId) {
                const clientApiClient = ApiClientManager.getInstance().getApiClient<ClientApiClient>(ClientApiClient.key);
                const additionalData = await clientApiClient.GetClientAdditionalData(clientId);

                setAdditionalData(additionalData);
            }
        }

        fetchAdditionalData();
    }, [clientId]);

    return (
        <div>
            {additionalData?.legalNotice ? additionalData.legalNotice : ""}
        </div>
    );
}