import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import frLocale from 'i18n-iso-countries/langs/fr.json';

countries.registerLocale(enLocale);
countries.registerLocale(frLocale);

export const GetCountryList = (locale: string): {id: string, name: string}[] => {
    const names = countries.getNames(locale);
    const mappedRet = Object.keys(names).map(code => {
        return {
            id: code,
            name: names[code]
        }
    });

    // Sort by name
    return mappedRet.sort((a, b) => a.name.localeCompare(b.name));
}