import { Button, Card, Container, Row } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "../Redux/hooks";
import React from "react";
import { useClientIdNavigate } from "../Router";
import './SelectBrief.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt, faNewspaper, faRadio, faTv, faUser } from "@fortawesome/free-solid-svg-icons";
import { DataKey, ExternalSystemElement } from "../Business/dto/ExternalSystemElement";
import { RetrieveDataFromExternalSource } from "../Redux/Reducers/briefSlice";
import './SelectMedia.css';
import { Page } from "./Page";
import { useTranslation } from "react-i18next";
import { CanUserAllocate } from "../Redux/Reducers/userSlice";
import { toast } from "react-toastify";

export const SelectBrief = () => {
    const { t } = useTranslation();
    const clientId = useAppSelector(state => state.clientConfig.config?.id);
    const dispatch = useAppDispatch();
    const navigate = useClientIdNavigate();
    const userDetails = useAppSelector(state => state.user.details);
    const clientConfig = useAppSelector(state => state.clientConfig?.config);
    const media = useAppSelector(state => state.brief.retrievedData[DataKey.Media]);
    const userCanAllocate = useAppSelector(state => state.user.canAllocate);
    const [selectedBrief, setSelectedBrief] = React.useState<string>("");
    const [selectedMedia, setSelectedMedia] = React.useState<ExternalSystemElement | null>(null);
    const [nextButtonDisabled, setNextButtonDisabled] = React.useState<boolean>(true);

    React.useEffect(() => {
        dispatch(RetrieveDataFromExternalSource({ dataKey: DataKey.Media }));

        if (clientId)
            dispatch(CanUserAllocate(clientId));
    }, [dispatch, clientId]);

    React.useEffect(() => {
        if (userDetails) {
            if (userDetails.briefsByClient[0] && userDetails.briefsByClient[0].briefs.length === 1) {
                setSelectedBrief(userDetails.briefsByClient[0].briefs[0]);
            }
        }
    }, [userDetails]);

    React.useEffect(() => {
        if (selectedBrief !== "" && selectedMedia) {
            setNextButtonDisabled(false);
        }
        else {
            setNextButtonDisabled(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBrief, selectedMedia]);

    const onNextButton = () => {
        if (!userCanAllocate)
            toast.error(t("txt_allocation_count_exceeded"));
        else
            navigate("/brief", { state: { selectedBrief: selectedBrief, selectedMedia: selectedMedia } });
    }

    const onBriefCardClick = (brief: string) => {
        setSelectedBrief(brief);
    }

    const onMediaCardClick = (media: ExternalSystemElement) => {
        setSelectedMedia(media);
    };

    const renderBriefConfig = (brief: string, index: number) => {
        const isSelected = brief === selectedBrief;

        return (
            <Card key={index} className={"selectBriefCard" + (isSelected ? " selectedCard" : "")} onClick={() => onBriefCardClick(brief)}>
                <Card.Title className={"selectBriefCardTitle"}>
                    <FontAwesomeIcon icon={faUser} style={{ color: isSelected ? "white" : clientConfig?.primaryColor }} />
                </Card.Title>
                <Card.Text>
                    {brief}
                </Card.Text>
            </Card>
        );
    };

    const renderMediaIcon = (mediaName: string) => {
        const mediaLower = mediaName.toLowerCase();

        if (mediaLower === "tv" || mediaLower === "television" || mediaLower === "video")
            return faTv;
        else if (mediaLower === "newspaper" || mediaLower.includes("press"))
            return faNewspaper;
        else if (mediaLower === "radio")
            return faRadio
        else if (mediaLower === "internet" || mediaLower === "web" || mediaLower === "digital")
            return faMobileAlt;

        return faNewspaper;
    }

    const renderMedia = (media: ExternalSystemElement, index: number) => {
        const isSelected = selectedMedia?.name === media.name;

        return (
            <Card key={index} className={"selectMediaCard" + (isSelected ? " selectedCard" : "")} onClick={() => onMediaCardClick(media)}>
                <Card.Title className="selectMediaCardTitle" style={{ color: isSelected ? "white" : clientConfig?.primaryColor }}>
                    <FontAwesomeIcon icon={renderMediaIcon(media.name)} />
                </Card.Title>
                <Card.Text>
                    {media.name}
                </Card.Text>
            </Card>
        )
    };

    const renderPageActionButton = () => {
        return (
            <>
                <Button disabled={nextButtonDisabled} onClick={onNextButton} className="sectionContinueButton" variant="dark">
                    {t("next_button")}
                </Button>
            </>
        );
    }

    return (
        <Page actionButton={renderPageActionButton()} authenticated title="" category="txt_brief">
            <Row>
                {
                    userDetails && userDetails.briefsByClient[0] && userDetails.briefsByClient[0].briefs.length > 1 &&
                    (<>
                        <h1><b>{t("txt_select_brief")}</b></h1>
                        <Container className="selectBriefContainer">
                            {userDetails.briefsByClient[0].briefs.map(renderBriefConfig)}
                        </Container>
                    </>)
                }
                {
                    media &&
                    (
                        <>
                            <h1><b>{t("txt_select_media")}</b></h1>
                            <Container className="selectMediaContainer">
                                {media.map(renderMedia)}
                            </Container>
                        </>
                    )
                }
            </Row>
        </Page>
    );
};