import React from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthApiClient } from "../Business/api/AuthApiClient";
import { ApiClientManager } from "../Business/api/ApiClientManager";
import './Success.css'; // Import the CSS file
import { useClientIdNavigate } from "../Router";


export const Success = () => {
    const { t } = useTranslation();
    const { type } = useParams();
    const { token } = useParams();
    const navigate = useClientIdNavigate();
    const [message, setMessage] = React.useState<string>("");

    const redirect = (url?: string) => {
        setTimeout(() => {
            //window.location.href = `/${clientId}/` + (url ?? "");
            navigate(url ?? "");
        }, 3000);
    }

    React.useEffect(() => {
        if (type === "register") {
            setMessage(t("txt_account_validated"));
            try {
                ApiClientManager.getInstance().getApiClient<AuthApiClient>(AuthApiClient.key).verifyEmail(token || "")
            }
            catch (error: any) {
                console.log(error)
            }
            redirect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, t, token]);

    return (
        <Container fluid className="d-flex vh-100 success">
            <Row className="m-auto">
                <Col className="text-center">
                    <FontAwesomeIcon icon={faCircleCheck} className="success-icon" />
                    <p className="success-message">{message}</p>
                </Col>
            </Row>
        </Container>
    );
}
