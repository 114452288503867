import { Link } from "react-router-dom";
import { useAppSelector } from "../../Redux/hooks";
import { RoutingMode } from "../../Router";

// Custom Link component that automatically prefixes clientId to paths
const ClientLink = ({ to, ...rest }: any) => {
    const clientId = useAppSelector(state => state.clientConfig.config?.id);
    const routingMode: RoutingMode = process.env.REACT_APP_ROUTING_MODE as RoutingMode;
    const prefixedTo = `/${clientId}${to}`;

    if (routingMode === RoutingMode.CLIENT_ID)
        return <Link {...rest} to={prefixedTo} />;
    else
        return <Link {...rest} to={to} />;
};

export default ClientLink;