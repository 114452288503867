import { useTranslation } from "react-i18next";
import { DefaultInput } from "./DefaultInput";
import { Advertiser } from "../../Business/dto/Brief";

interface DiscountRateInputProps {
    setValues: (value: number, key: string) => void;
    disabled: boolean;
    value: number;
    advertiser: Advertiser | undefined;
    startDate: number | undefined;
}

export const DiscountRateInput = (props: DiscountRateInputProps) => {
    const { t } = useTranslation();
    return (
        <>
            <DefaultInput
                onChange={(value: any) => props.setValues(parseFloat(value), "sellingStrategyValue")}
                type="number"
                iconKey="percent"
                placeholder={t("txt_value")}
                disabled={props.disabled}
                value={props.value}
            />
        </>
    )
}