import React from "react";
import './PhoneNumberInput.css';
import 'react-international-phone/style.css';
import { InputGroup } from "react-bootstrap";
import { CountryData, PhoneInput, PhoneInputRefType, buildCountryData, defaultCountries, parseCountry } from "react-international-phone";
import ReactDOM from "react-dom";
import { useAppSelector } from "../../Redux/hooks";

interface PhoneNumberInputProps {
    required?: boolean;
    placeholder: string;
    countries: { id: string, name: string }[];
    value: string;
    onChange: (value: string) => void;
}

export const PhoneNumberInput = (props: PhoneNumberInputProps) => {
    const [finalCountries, setFinalCountries] = React.useState<CountryData[]>([]);
    const clientConfig = useAppSelector(state => state.clientConfig.config);
    const ref = React.useRef<PhoneInputRefType>(null);

    React.useEffect(() => {
        if (props.countries && props.countries.length > 0) {
            let tmpCountries = defaultCountries.map((country) => {
                const parsedCountry = parseCountry(country);

                parsedCountry.name = props.countries.find(c => c.id.toLowerCase() === parsedCountry.iso2)?.name || parsedCountry.name;

                return parsedCountry;
            });

            // sort by name
            tmpCountries = tmpCountries.sort((a, b) => a.name.localeCompare(b.name));

            setFinalCountries(tmpCountries.map(buildCountryData));
        }
    }, [props.countries])

    const onFocus = () => {
        (ReactDOM.findDOMNode(ref.current) as any).style.boxShadow = `0 0 0 0.05rem ${clientConfig?.primaryColor}`;
        (ReactDOM.findDOMNode(ref.current) as any).style.borderRadius = '0.25rem';
    }

    const onBlur = () => {
        (ReactDOM.findDOMNode(ref.current) as any).style.boxShadow = 'none';
        (ReactDOM.findDOMNode(ref.current) as any).style.borderRadius = '0';
    }

    return (
        <InputGroup ref={ref} className="defaultInputGroup">
            <PhoneInput
                defaultCountry="fr"
                countries={finalCountries && finalCountries.length > 0 ? finalCountries : defaultCountries}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                required={props.required}
                inputProps={{
                    required: props.required,
                }}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        </InputGroup>
    );
}