import { IconDefinition, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";
import Select, { StylesConfig, components, ControlProps } from "react-select";
import CreatableSelect from "react-select/creatable";
import './ChoicePicker.css';
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../Redux/hooks";

export class ChoicePickerOption {
    id: string = "";
    name: string = "";

    toString() {
        return this.name;
    }
}

interface ChoicePickerProps {
    setSelectedData?: (data: any) => void;
    placeholder: string;
    displayCode?: boolean;
    data: ChoicePickerOption[];
    multiple?: boolean;
    disabled?: boolean;
    icon?: IconDefinition;
    required?: boolean;
    controlId: string;
    value?: any[];
    allowNew?: boolean;
}

const Control = ({ children, ...props }: ControlProps<ChoicePickerOption, boolean>) => {
    // @ts-ignore
    const { icon } = props.selectProps;

    return (
        <components.Control {...props}>
            <span className="inputIconSelect"><FontAwesomeIcon icon={icon ? icon : faSearch} /></span>
            {children}
        </components.Control>
    )
}

export const ChoicePicker = (props: ChoicePickerProps) => {
    const { t } = useTranslation();
    const clientConfig = useAppSelector(state => state.clientConfig.config);

    React.useEffect(() => {
        if (props.data && props.data.length === 1 && props.value && props.value.length === 0 && props.setSelectedData) {
            if (!props.multiple)
                props.setSelectedData(props.data[0]);
            else
                props.setSelectedData([props.data[0]])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    const onSelected = (selected: any[]) => {
        if (props.setSelectedData) {
            if (props.multiple) {
                props.setSelectedData(selected);
            }
            else {
                props.setSelectedData(selected[0]);
            }
        }
    }

    const SelectComponent = props.allowNew ? CreatableSelect : Select;

    const colourStyles: StylesConfig<ChoicePickerOption, boolean> = {
        control: (styles, { isDisabled }) => ({ 
            ...styles,
            backgroundColor: isDisabled ? "#E9ECEF" : "white",
            borderColor: "#DEE2E6",
            ":hover": {
                ...styles[":focus"],
                borderColor: "#DEE2E6",
            },
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                        ? clientConfig?.primaryColor
                        : isFocused
                            ? clientConfig?.secondaryColor
                            : undefined,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? 'white'
                        : 'black'
                ,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? clientConfig?.primaryColor
                            : clientConfig?.primaryColor
                        : undefined,
                },
            };
        },
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: clientConfig?.primaryColor,
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "white",
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "white",
            ':hover': {
                backgroundColor: clientConfig?.secondaryColor,
                color: 'white',
            },
        }),
    };

    return (
        <Form.Group controlId={props.controlId}>
            <SelectComponent<ChoicePickerOption, boolean>
                components={{ Control }}
                required={props.required}
                isDisabled={props.disabled}
                className="choicePicker"
                id="choicePicker"
                form={props.controlId}
                options={props.data}
                formatCreateLabel={(inputValue) => `${t("txt_new")}: ${inputValue}`}
                isValidNewOption={(inputValue) => inputValue.length > 0}
                noOptionsMessage={() => "..."}
                getOptionLabel={(option: any) => {
                    if (props.displayCode)
                        return `${option.name} (${option.code})`;
                    else
                        return option.name;
                }}
                formatOptionLabel={(option: any) => option.name ?? option.label}
                getOptionValue={(option: ChoicePickerOption) => option.id}
                placeholder={props.placeholder}
                isClearable
                value={props.value}
                blurInputOnSelect={true}
                isMulti={props.multiple}
                // @ts-ignore
                icon={props.icon}
                styles={colourStyles}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 5,
                    colors: {
                        ...theme.colors,
                        primary: clientConfig?.primaryColor ?? 'black',
                    }
                })}
                onChange={(selected, actionMeta: any) => {
                    if (actionMeta.action === "clear")
                        onSelected([]);
                    else if (actionMeta.action === "select-option") {
                        // selected can be an array or a single object
                        if (props.multiple && props.value && props.value.length > 0) {
                            if (Array.isArray(selected))
                                onSelected(selected);
                            else
                                onSelected([...props.value, selected]);
                        }
                        else {
                            if (Array.isArray(selected))
                                onSelected(selected);
                            else
                                onSelected([selected]);
                        }
                    }
                    else if (actionMeta.action === "create-option") {
                        const newOption = { id: "new-id-" + actionMeta.option.value, name: actionMeta.option.label };

                        if (props.multiple && props.value && props.value.length > 0)
                            onSelected([...props.value, newOption]);
                        else
                            onSelected([newOption]);
                    }
                    else if (actionMeta.action === "remove-value" && Array.isArray(selected)) {
                        onSelected(selected);
                    }
                }}
            />
        </Form.Group>
    );
}