import React from "react";
import { useAppDispatch, useAppSelector } from "../Redux/hooks";
import { SearchBriefs } from "../Redux/Reducers/briefSlice";
import { Advertiser, BriefDTO, Product } from "../Business/dto/Brief";
import { t } from "i18next";
import "./AllocationHistory.css";
import { BasicTable } from "../Components/BasicTable";
import { TableColumn } from "react-data-table-component";
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import { dateObjectToNumber, numberToDateObject } from "../Business/utils/DateUtils";
import { formatNumberToCurrency } from "../Business/utils/CurrencyUtils";
import { GetBriefStatus } from "../Business/utils/BriefUtils";
import "./AllocationHistory.css";
import { ClientIdLink } from "../Router";
import { UserRoles } from "../Business/dto/UserDetails";
import { GetUserList } from "../Redux/Reducers/userSlice";
import { Col, Row } from "react-bootstrap";
import { Input } from "../Components/Inputs/Input";
import { PeriodPicker } from "../Components/Inputs/PeriodPicker";
import { ChoicePicker } from "../Components/Inputs/ChoicePicker";

const threeMonthsAgo = moment().subtract(3, "months").toDate();

export const AllocationHistory = () => {
    const dispatch = useAppDispatch();
    const locale = useAppSelector(state => state.locale.locale);
    moment.locale(locale);
    const clientId = useAppSelector(state => state.clientConfig.config?.id);
    const history = useAppSelector(state => state.brief.history);
    const [columns, setColumns] = React.useState<TableColumn<BriefDTO>[]>([]);
    const user = useAppSelector(state => state.user.details);
    const users = useAppSelector(state => state.user.userList);
    const [filterStartDate, setFilterStartDate] = React.useState<number>(dateObjectToNumber(threeMonthsAgo));
    const [filterEndDate, setFilterEndDate] = React.useState<number>(dateObjectToNumber(new Date()));
    const [filterUser, setFilterUser] = React.useState<{ id: string, name: string } | undefined>(undefined);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    React.useEffect(() => {
        const tmpCols: TableColumn<BriefDTO>[] = ([
            {
                name: t("txt_date_demande"),
                selector: row => moment((row as any).createdAt).format("L LT"),
                sortable: true,
                sortFunction: (a, b) => moment((a as any).createdAt).unix() - moment((b as any).createdAt).unix(),
                wrap: false,
                allowOverflow: true
            },
            {
                name: t("txt_start_date"),
                selector: row => moment(numberToDateObject(row.brief.StartDate)).format("L"),
                sortable: true,
                sortFunction: (a, b) => moment(numberToDateObject(a.brief.StartDate)).unix() - moment(numberToDateObject(b.brief.StartDate)).unix()
            },
            {
                name: t("txt_end_date"),
                selector: row => moment(numberToDateObject(row.brief.EndDate)).format("L"),
                sortable: true,
                sortFunction: (a, b) => moment(numberToDateObject(a.brief.EndDate)).unix() - moment(numberToDateObject(b.brief.EndDate)).unix()
            },
            {
                name: t("annoncer"),
                selector: row => (row.brief.Advertiser as Advertiser).name,
                sortable: true
            },
            {
                name: t("product"),
                selector: row => (row.brief.Product as Product).name,
                sortable: true
            },
            {
                name: t("budget"),
                selector: row => formatNumberToCurrency(row.brief.Budget, row.brief.Currency),
                sortable: true
            },
            {
                name: t("txt_status"),
                selector: row => t(GetBriefStatus(row).text),
                cell: (row, _rowIdx, _column, _id) => {
                    const status = GetBriefStatus(row);

                    return <div className={`allocationStatusBadge-${status.color}`}>{t(status.text)}</div>
                },
                sortable: true,
                sortFunction: (a, b) => GetBriefStatus(a).text.localeCompare(GetBriefStatus(b).text)
            },
            {
                name: t("txt_details"),
                cell: (row, _rowIdx, _column, _id) => {
                    if (row.allocatorSessionId === undefined || row.allocatorSessionRunId === undefined || row.allocatedMediaPlans === undefined || row.allocatedMediaPlans === 0)
                        return <></>

                    return (
                        <ClientIdLink to={`/allocatorSolutions/${row.allocatorSessionRunId}`} props={{ state: { briefId: (row as any)._id } }}>
                            {t("txt_details")}
                        </ClientIdLink>
                    );
                }
            }
        ]);

        if (user?.roles.includes(UserRoles.ADMIN)) {
            tmpCols.splice(1, 0, {
                name: t("txt_user"),
                selector: row => users.find(user => user._id === (row as any).userId)?.firstName + " " + users.find(user => user._id === (row as any).userId)?.lastName,
                sortable: true
            });
        }

        setColumns(tmpCols);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users])

    React.useEffect(() => {
        dispatch(SearchBriefs({ clientId }));
    }, [dispatch, clientId]);

    React.useEffect(() => {
        if (user?.roles.includes(UserRoles.ADMIN))
            dispatch(GetUserList());
    }, [dispatch, user]);

    React.useEffect(() => {
        setResetPaginationToggle(!resetPaginationToggle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterUser, filterStartDate, filterEndDate]);

    const finalHistory = history.filter(brief => {
        // find user
        if (filterUser && (brief as any).userId !== filterUser.id)
            return (false);

        // find date
        const creationDate = moment((brief as any).createdAt).toDate();
        const creationDateNumber = dateObjectToNumber(creationDate);

        if (creationDateNumber < filterStartDate || creationDateNumber > filterEndDate)
            return (false);
        
        return (true);
    });

    return (
        <>
            <Row>
                <Col>
                    <Input title={t("txt_date_demande")}>
                        <PeriodPicker
                            startDate={filterStartDate}
                            endDate={filterEndDate}
                            setStartDate={setFilterStartDate}
                            setEndDate={setFilterEndDate}
                        />
                    </Input>
                </Col>
                <Col>
                    {user?.roles.includes(UserRoles.ADMIN) && <Input title={t("txt_user")}>
                        <ChoicePicker
                            placeholder={t("txt_user")}
                            data={users.map(user => ({ id: user._id, name: user.firstName + " " + user.lastName })).sort((a, b) => a.name.localeCompare(b.name))}
                            value={[filterUser]}
                            controlId="user"
                            setSelectedData={setFilterUser}
                        />
                    </Input>}
                </Col>
            </Row>
            <Row>
                <BasicTable<BriefDTO>
                    data={finalHistory}
                    columns={columns}
                    pagination={{ itemsPerPage: 10 }}
                    sortOptions={{ defaultSortColumnIdx: 1, defaultSortDirection: "desc" }}
                    keyField="_id"
                    resetPaginationToggle={resetPaginationToggle}
                />
            </Row>
        </>
    );
};