import { AllocationResult } from "../dto/AllocationSolution";
import { ApiResponse } from "../dto/ApiResponse";
import { BriefDTO } from "../dto/Brief";
import { resolveUrl } from "../utils/UrlResolver";
import { ApiClient } from "./ApiClient";

export class AllocationApiClient extends ApiClient {
    public static key = "AllocationApiClient";

    public async SendBrief(accessToken: string, brief: BriefDTO): Promise<any> {
        return await this.Post(resolveUrl(`${this.baseUrl}`, `/allocation/sendBrief`), { Brief: brief.brief }, accessToken);
    }

    public async StartAllocation(accessToken: string, sessionRunId: string): Promise<any> {
        return await this.Post(resolveUrl(`${this.baseUrl}`, `/allocation/startAllocation/${sessionRunId}`), {}, accessToken);
    }

    public async UpdateScreens(accessToken: string, brief: BriefDTO, sessionId: string, sessionRunId: string): Promise<any> {
        return await this.Post(resolveUrl(`${this.baseUrl}`, `/allocation/updateScreens`), { sessionId, sessionRunId, brief: {Brief: brief.brief} }, accessToken);
    }

    public async PushSelectedSolution(accessToken: string, sessionRunId: string, selectedSolution: any, selectedMediaPlanIdx: number, locale: string): Promise<any> {
        return await this.Post(resolveUrl(`${this.baseUrl}`, `/allocation/pushSelectedSolution`), { sessionRunId, selectedSolution, selectedMediaPlanIdx, locale }, accessToken);
    }

    public async GetAllocationResults(accessToken: string, sessionRunId: string): Promise<AllocationResult> {
        return (await this.Get<ApiResponse<AllocationResult>>(resolveUrl(`${this.baseUrl}`, `/allocation/getAllocationResults/${sessionRunId}`), accessToken)).data;
    }
}