import { Col, Container, Navbar, NavDropdown, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../Redux/hooks"
import './Page.css';
import React, { useContext } from "react";
import { Header } from "../Components/Misc/Header";
import { RoutingMode, useClientIdNavigate } from "../Router";
import { useParams } from "react-router-dom";
import { GetClientConfig } from "../Redux/Reducers/clientSlice";
import { PulseLoader } from "react-spinners";
import { GetUserDetails } from "../Redux/Reducers/userSlice";
import { useTranslation } from "react-i18next";
import { UserRoles } from "../Business/dto/UserDetails";
import JamContext from "../Components/Misc/JamContext";
import { logout, setLoggedInAt } from "../Redux/Reducers/authSlice";
import { toast } from "react-toastify";

export interface PageProps {
    children: React.ReactNode;
    authenticated?: boolean;
    admin?: boolean;
    withoutMarginAndHeader?: boolean;
    title: string;
    category?: string;
    actionButton?: React.ReactNode;
}

export const Page = (props: PageProps) => {
    const { t } = useTranslation();
    const navigate = useClientIdNavigate();
    const dispatch = useAppDispatch();
    const { clientId } = useParams();
    const locale = useAppSelector(state => state.locale.locale);
    const context = useContext(JamContext);
    const clientConfig = useAppSelector(state => state.clientConfig.config);
    const userDetails = useAppSelector(state => state.user.details);
    const loggedInAt = useAppSelector(state => state.auth.loggedInAt);
    const accessToken = useAppSelector(state => state.auth.accessToken);
    const clientConfigError = useAppSelector(state => state.clientConfig.loadConfigError);
    const [pageLoaded, setPageLoaded] = React.useState(false);
    const routingMode: RoutingMode = process.env.REACT_APP_ROUTING_MODE as RoutingMode;

    const changeLanguage = (lang: string) => {
        context.handleChange({ target: { value: lang } });
    }

    React.useEffect(() => {
        if (props.authenticated) {
            if (accessToken === "")
                navigate(`/`);
            else {
                dispatch(GetUserDetails());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken]);

    React.useEffect(() => {
        // Check if user has been logged in for more than a day
        if (loggedInAt === 0 && props.authenticated) {
            toast.error(t("session_timeout"));
            dispatch(logout());
        }
        else {
            if (loggedInAt > 0 && (Date.now() - loggedInAt) > 86400000) {
                toast.error(t("session_timeout"));
                dispatch(logout());
            }
            else if (props.authenticated) {
                dispatch(setLoggedInAt(Date.now()));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (clientConfig) {
            if (props.authenticated && userDetails) {
                setPageLoaded(true);
                console.log("page loaded")
                //socket.connect();

                if (props.admin && !userDetails.roles.includes(UserRoles.ADMIN))
                    navigate(`/`);
            }
            else if (!props.authenticated)
                setPageLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientConfig, userDetails, props.authenticated]);

    React.useEffect(() => {
        console.log("Client config error : ", clientConfigError);
    }, [clientConfigError])

    React.useEffect(() => {
        if (clientId && routingMode === RoutingMode.CLIENT_ID)
            dispatch(GetClientConfig({ mode: routingMode, param: clientId }));
        else if (routingMode === RoutingMode.DOMAIN) {
            dispatch(GetClientConfig({ mode: routingMode, param: window.location.origin + '/' }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId])

    return (
        <>
            {pageLoaded ?
                (props.withoutMarginAndHeader ?
                    <div>
                        <NavDropdown className="floatingLocaleSelector" title={locale}>
                            <NavDropdown.Item onClick={() => changeLanguage("fr")}>Fr</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => changeLanguage("en")}>En</NavDropdown.Item>
                        </NavDropdown>
                        {props.children}
                    </div>
                    :
                    <div>
                        <Header />
                        <Container className="mainContainer appBaseContainer">
                            <b className="categoryTitle">{(props.category && props.category !== "") && t(props.category)}</b>
                            <h1><b>{(props.title && props.title !== "") && t(props.title)}</b></h1>
                            <div>
                                {props.children}
                            </div>
                        </Container>
                        {props.actionButton &&
                            <Navbar fixed="bottom" className="footer">
                                <Container>
                                    <Row style={{ width: "100%" }}>
                                        <Col style={{ display: 'flex', justifyContent: 'center' }} md={12} sm={12} xs={12}>
                                            {props.actionButton}
                                        </Col>
                                    </Row>
                                </Container>
                            </Navbar>
                        }
                    </div>
                )
                :
                <div className="fullPageLoader">
                    <PulseLoader
                        aria-label="Loading"
                    />
                </div>
            }
        </>
    );
}