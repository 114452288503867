import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import './Home.css';
import { DefaultInput } from '../Components/Inputs/DefaultInput';
import { useAppDispatch, useAppSelector } from '../Redux/hooks';
import { LoginAction } from '../Redux/Reducers/authSlice';
import React from 'react';
import { useClientIdNavigate } from '../Router';
import ClientLink from '../Components/Misc/ClientLink';
import { Input } from '../Components/Inputs/Input';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const Home = () => {
    const { t } = useTranslation();
    const clientId = useAppSelector(state => state.clientConfig.config?.id);
    const navigate = useClientIdNavigate();
    const dispatch = useAppDispatch();
    const accessToken = useAppSelector((state) => state.auth.accessToken);
    const loginError = useAppSelector((state) => state.auth.loginError);

    const onFormSubmit = (event: any) => {
        event.preventDefault();
        if (clientId)
            dispatch(LoginAction({ email:event.target[0].value, password: event.target[1].value, clientId }));
    }

    React.useEffect(() => {
        if (accessToken !== ""){
            toast.success(t("txt_login_success_notification"));
            navigate('/selectBrief', { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken]);

    React.useEffect(() => {
        if (loginError !== ""){
            toast.error(t(loginError));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginError]);

    return (
        <Container fluid>
            <Row>
                <Col className="leftSection">
                    <Row className="mb-3">
                        <Col>
                            <img className="homeLogo" src="/img/JAM_ Logo&Slogan.svg" alt="JAM LOGO" />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <h1 className="homeTitle">{t('txt_login_h')}</h1>
                        </Col>
                    </Row>
                    <Form onSubmit={onFormSubmit}>
                        <Row className="mb-3">
                            <Col>
                                <Form.Group>
                                    <Input title={t("Email")}>
                                        <DefaultInput type="email" iconKey='email' placeholder={t('Email')} onChange={(_value) => { }} />
                                    </Input>
                                    <Input title={t("txt_pass_label")}>
                                        <DefaultInput type="password" iconKey='password' placeholder={t('txt_pass_label')} onChange={(_value) => { }} />
                                    </Input>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                <Form.Check // prettier-ignore
                                    type="checkbox"
                                    id={`default-checkbox`}
                                    label="Se souvenir de moi"
                                />
                            </Col>
                            <Col className='forgotPassword'>
                                <ClientLink to="/forgotPassword">{t("txt_forgot_pass")}</ClientLink>
                            </Col>
                        </Row>
                        <Row className="mb-3 ">
                            <Col>
                                <Button className="loginButton" type="submit">{t("txt_login_h")}</Button>
                            </Col>
                        </Row>
                    </Form>
                    <Row className='registerText' >
                        <hr />
                        <ClientLink to="/register">{t("txt_register")}</ClientLink>
                    </Row>
                </Col>
                <Col className="d-none d-md-block rightSection">
                    <img className="imgRightSection" src="/img/RightSection.svg" alt="Right Section" />
                </Col>
            </Row>
        </Container >
    )
};